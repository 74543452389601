import React from 'react';
import { CircularProgress, styled, Typography } from '@mui/material';

const StyledDiv = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
});

const FullScreenSpinner = ({
  message,
  style,
}: {
  message?: string;
  style?: any;
}) => (
  <StyledDiv style={style}>
    <CircularProgress size={50} color="error" />
    {message && (
      <Typography variant="body1" component="h2">
        {message}
      </Typography>
    )}
  </StyledDiv>
);

export default FullScreenSpinner;
