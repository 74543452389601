import * as React from 'react';

import { logout } from 'api';
import { useAuth } from 'components/Authentication/AuthenticationProvider';
import AccountWidgetView from './AccountWidgetView';

const AccountWidget = () => {
  const { username } = useAuth();

  const doLogout = async () => {
    if (
      /* eslint no-alert: "off" */
      window.confirm(
        'Are you sure you’d like to sign out? You may be signed in to other tools using the same credentials; if you sign out here, you may be required to sign in again elsewhere.',
      )
    ) {
      await logout();
    }
  };

  return <AccountWidgetView username={username} doLogout={doLogout} />;
};

export default AccountWidget;
