import { useEffect, useState } from 'react';

type PaginationType =
  | 'page'
  | 'first'
  | 'last'
  | 'next'
  | 'previous'
  | 'start-ellipsis'
  | 'end-ellipsis';
const DEFAULT_ITEMS_PER_PAGE = 10;

const usePagination = <T>(
  items: T[],
  itemsPerPage = DEFAULT_ITEMS_PER_PAGE,
) => {
  const useGetPaginationAriaLabel = () => {
    return (type: PaginationType, page: number, selected: boolean) => {
      if (type === 'page') {
        return selected ? `selected page ${page}` : `unselected page ${page}`;
      }
      if (type === 'next') {
        return 'next page';
      }
      return '';
    };
  };

  const getPaginationAriaLabel = useGetPaginationAriaLabel();
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    setPageNumber(1);
  }, [items]);

  const firstItem = (pageNumber - 1) * itemsPerPage;
  const lastItem = firstItem + itemsPerPage;
  const pagedItems = items.slice(firstItem, lastItem);
  const handlePageChange = (page: number) => setPageNumber(page);
  const pageCount = Math.ceil(items.length / itemsPerPage);

  return {
    pageNumber,
    pageCount,
    pagedItems,
    handlePageChange,
    getPaginationAriaLabel,
  };
};

export default usePagination;
