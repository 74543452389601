import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { useGetTranslationsKeys } from 'api/hooks';
import { JobTranslation } from 'types/job';
import { FullScreenError, FullScreenSpinner } from 'components/FullScreen';
import { IDialects } from 'variables';
import SearchPanel from './SearchPanel';
import BottomBar from './BottomBar';
import SearchList from './SearchList';

const halfHeight = { height: '50vh' };

type PropsType = {
  jobId?: string;
  dialect: keyof IDialects;
  translations: JobTranslation[];
  onSaveSelection: (selectedStrings: string[]) => void;
  saveSelectionDisabled: boolean;
};

const Search = ({
  dialect,
  jobId,
  onSaveSelection,
  saveSelectionDisabled,
  translations,
}: PropsType) => {
  const [searchText, setSearchText] = React.useState<string | undefined>(
    undefined,
  );
  const [selectedFilter, setSelectedFilter] = React.useState<string[]>([]);
  const existingTranslations = React.useMemo(
    () => translations?.map((k) => k.key) || [],
    [translations],
  );
  const [selectedStrings, setSelectedStrings] =
    React.useState<string[]>(existingTranslations);
  const {
    data: translationCategoryKeys,
    isPending,
    isError,
  } = useGetTranslationsKeys();

  const onSubmit = (newSearchText: string, newFilters: string[]) => {
    setSearchText(newSearchText);
    setSelectedFilter(newFilters);
  };

  if (isPending) {
    return <FullScreenSpinner message="Loading..." style={halfHeight} />;
  }
  if (isError) {
    return (
      <FullScreenError
        message="There was an error fetching category keys data, please try refresh the page."
        style={halfHeight}
      />
    );
  }

  return (
    <Container disableGutters>
      <Grid container>
        <Grid item sm={12}>
          <SearchPanel
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            translationCategoryKeys={translationCategoryKeys!}
            onSubmit={onSubmit}
          />
        </Grid>
        <Grid item sm={12}>
          <SearchList
            jobId={jobId}
            dialect={dialect}
            translations={translations}
            searchText={searchText}
            selectedFilter={selectedFilter}
            selectedStrings={selectedStrings}
            setSelectedStrings={setSelectedStrings}
          />
        </Grid>
      </Grid>

      {selectedStrings.length > 0 && (
        <Box marginTop={7}>
          <BottomBar
            stringCount={selectedStrings.length}
            onProceed={() => onSaveSelection(selectedStrings)}
            onCancel={() => setSelectedStrings([])}
            disabled={saveSelectionDisabled}
          />
        </Box>
      )}
    </Container>
  );
};

export default Search;
