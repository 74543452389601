import * as React from 'react';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { Text } from '@insights-ltd/design-library/components';
import { IJob } from 'types/job';

interface IPropsType {
  onSort: (property: keyof IJob) => void;
  order: 'asc' | 'desc';
  orderBy: string;
}

interface Cell {
  id: keyof IJob;
  label: string;
  sortable?: boolean;
}

const cells: Cell[] = [
  { id: 'id', label: 'Job ID', sortable: true },
  { id: 'dialect', label: 'Lang', sortable: true },
  { id: 'title', label: 'Name', sortable: true },
  { id: 'status', label: 'Status', sortable: true },
  { id: 'deadlineDate', label: 'Deadline', sortable: true },
  { id: 'owner', label: 'Owner', sortable: true },
  { id: 'requester', label: 'Requester', sortable: true },
  { id: 'translator', label: 'Translator', sortable: true },
];

const Header = (props: IPropsType) => {
  const { order, orderBy, onSort } = props;
  const sortHandler = (property: keyof IJob) => () => {
    onSort(property);
  };

  return (
    <TableHead>
      <TableRow>
        {cells.map((cell) => {
          return cell.sortable ? (
            <TableCell
              key={cell.id}
              align="left"
              sortDirection={orderBy === cell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === cell.id}
                direction={orderBy === cell.id ? order : 'asc'}
                onClick={sortHandler(cell.id)}
              >
                <Text variant="body-bold" color="textSecondary">
                  {cell.label}
                </Text>
              </TableSortLabel>
            </TableCell>
          ) : (
            <TableCell key={cell.id} align="left">
              <Text variant="body-bold" color="textSecondary">
                {cell.label}
              </Text>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

export default Header;
