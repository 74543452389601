import { NoResponse, RequestErrorBody } from 'api';
import { get, post, put } from '@insights-ltd/design-library/utils';
import {
  IJobsResponse,
  ITranslationsResponse,
  TranslationKeys,
} from 'types/api';
import { IUser } from 'types/auth';
import { IJob } from 'types/job';

const API_ROOT = `${import.meta.env.VITE_API_PREFIX}/api/v1`;

type BadRequestResponse = { message: string };

export const authentication = {
  getUser: get<IUser | BadRequestResponse>(`${API_ROOT}/user`),
  logOut: post<{ url: string }>(`${API_ROOT}/logout`),
};

export const jobs = {
  all: get<IJobsResponse | BadRequestResponse>(`${API_ROOT}/jobs`),
  get: (jobId: string) =>
    get<IJob | BadRequestResponse>(`${API_ROOT}/jobs/${jobId}`),
  create: post<IJob | BadRequestResponse>(`${API_ROOT}/jobs`),
  update: (jobId: string) =>
    put<NoResponse | BadRequestResponse>(`${API_ROOT}/jobs/${jobId}`),
  updateTranslations: (jobId: string) =>
    put<NoResponse | BadRequestResponse>(
      `${API_ROOT}/jobs/${jobId}/translations`,
    ),
  publish: (jobId: string) =>
    post<NoResponse | BadRequestResponse>(`${API_ROOT}/jobs/${jobId}`),
};

export const jobTranslations = {
  get: (jobId: string) =>
    get<ITranslationsResponse | BadRequestResponse>(
      `${API_ROOT}/jobs/${jobId}/translations`,
    ),
  update: (jobId: string) =>
    put<NoResponse | BadRequestResponse>(
      `${API_ROOT}/jobs/${jobId}/translations`,
    ),
};

export const translations = {
  all: get<ITranslationsResponse | RequestErrorBody>(
    `${API_ROOT}/translations`,
  ),
  uploadFiles: (id: string) =>
    post<NoResponse | RequestErrorBody>(`${API_ROOT}/translation-files/${id}`),
};

export const translationKeys = {
  all: get<TranslationKeys>(`${API_ROOT}/translations/keys`),
};

export const importStrings = (jobId: string) =>
  post<NoResponse | BadRequestResponse>(
    `${API_ROOT}/translation-files/${jobId}`,
  );
