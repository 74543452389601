import React from 'react';

import Select, { SelectProps } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import owners from 'testData/owners';

const OwnersSelect = React.forwardRef<HTMLSelectElement, SelectProps>(
  ({ SelectDisplayProps = { 'aria-label': 'Owners' }, ...rest }, ref) => (
    <Select SelectDisplayProps={SelectDisplayProps} inputRef={ref} {...rest}>
      {owners.map((item) => (
        <MenuItem key={item.id} value={item.name}>
          {item.name}
        </MenuItem>
      ))}
    </Select>
  ),
);

OwnersSelect.displayName = 'OwnersSelect';

export default OwnersSelect;
