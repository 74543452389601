import React, { useEffect } from 'react';
import { Grid, IconButton, Theme, useMediaQuery } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import HeaderLayout from './HeaderLayout';
import MobileNavBarDialog from './MobileNavBarDialog';
import { NavBar } from './NavBar';

const Header = () => {
  const aboveMobile = useMediaQuery(
    (theme: Theme) => theme.breakpoints.up('sm'),
    { noSsr: true },
  );
  const [dialogOpen, setDialogOpen] = React.useState(false);

  useEffect(() => {
    if (aboveMobile) setDialogOpen(false);
  }, [aboveMobile]);

  return (
    <>
      <HeaderLayout>
        {aboveMobile ? (
          <NavBar />
        ) : (
          <Grid container justifyContent="flex-end" data-testid="mobile-header">
            <IconButton
              onClick={() => setDialogOpen(true)}
              aria-label="Open Menu Button"
              size="small"
              sx={{ textAlign: 'right' }}
            >
              <MenuIcon />
            </IconButton>
          </Grid>
        )}
      </HeaderLayout>
      <MobileNavBarDialog
        open={dialogOpen}
        closeDialog={() => setDialogOpen(false)}
      />
    </>
  );
};

export default Header;
