import { Container } from '@mui/material';
import React from 'react';

const AppWrapper = ({ children }: { children: JSX.Element }) => (
  <Container
    disableGutters
    maxWidth="xl"
    sx={(theme) => ({ paddingBottom: theme.spacing(3) })}
  >
    {children}
  </Container>
);

export default AppWrapper;
