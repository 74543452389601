import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Alert, Container, Snackbar } from '@mui/material';
import { StringHeader, StringSearch } from 'components/Strings';
import { useGetJob, useSaveStrings } from 'api/hooks';
import { FullScreenError, FullScreenSpinner } from 'components/FullScreen';
import { JobRouteParams } from 'types';

const SearchPage = () => {
  const navigate = useNavigate();
  const { jobId } = useParams<JobRouteParams>();
  const { status: getJobStatus, data } = useGetJob(jobId || '');
  const {
    mutate: saveStrings,
    status: stringsSaveStatus,
    reset,
  } = useSaveStrings();

  if (getJobStatus === 'pending') {
    return <FullScreenSpinner message="Loading..." />;
  }
  if (getJobStatus === 'error') {
    return (
      <FullScreenError message="There was an error finding the job, please try refresh the page." />
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const job = data!;

  const onSaveSelection = (selectedStrings: string[]) =>
    saveStrings(
      { jobId: job.id, keys: selectedStrings },
      { onSuccess: () => navigate(`/jobs/${job.id}`) },
    );

  return (
    <Container maxWidth="lg">
      <StringHeader job={job} />
      <StringSearch
        key={jobId}
        jobId={job.id}
        translations={job.translations || []}
        dialect={job.dialect}
        onSaveSelection={onSaveSelection}
        saveSelectionDisabled={stringsSaveStatus === 'pending'}
      />
      <Snackbar
        open={stringsSaveStatus === 'error'}
        autoHideDuration={6000}
        onClose={reset}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert elevation={6} variant="filled" onClose={reset} severity="error">
          Failed to save selection. Please try again later.
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default SearchPage;
