import Typography from '@mui/material/Typography';
import * as React from 'react';
import { JobStatus, TRANSLATION_JOB_STATUSES } from 'variables';
import { getTheme } from '@insights-ltd/design-library/themes';

const localTheme = getTheme();

export const JobsStatus = ({ jobStatus }: { jobStatus: JobStatus }) => {
  let selectedBackground;
  let selectedBorder;
  let selectedColor;

  switch (jobStatus) {
    case 'DRAFT':
      selectedBackground = localTheme.brandColours.lightBlue;
      selectedColor = localTheme.brandColours.blue;
      break;
    case 'AWAITING_QUOTE':
      selectedBackground = localTheme.brandColours.lightOrange;
      selectedColor = localTheme.brandColours.orange;
      break;
    case 'WITH_TRANSLATOR':
      selectedBorder = `1px solid ${localTheme.brandColours.orange}`;
      selectedColor = localTheme.brandColours.orange;
      break;
    case 'IN_REVIEW':
      selectedBorder = `1px solid ${localTheme.brandColours.green}`;
      selectedColor = localTheme.brandColours.green;
      break;
    case 'PENDING_PUBLISH':
      selectedBorder = `1px solid ${localTheme.brandColours.blue}`;
      selectedColor = localTheme.brandColours.blue;
      break;
    case 'PUBLISHED':
      selectedBackground = localTheme.brandColours.lightGreen;
      selectedColor = localTheme.brandColours.green;
      break;
    case 'CANCELLED':
      selectedBorder = `1px solid ${localTheme.validationColours.error}`;
      selectedColor = localTheme.validationColours.error;
      break;
    default:
      break;
  }

  return (
    <Typography
      sx={{
        display: 'inline-block',
        paddingRight: '10px',
        paddingLeft: '10px',
        marginTop: '8px',
        marginBottom: '8px',
        fontWeight: 'bold',
        borderRadius: '20px',
        background: selectedBackground,
        border: selectedBorder,
        color: selectedColor,
      }}
    >
      {TRANSLATION_JOB_STATUSES[jobStatus].displayName}
    </Typography>
  );
};
