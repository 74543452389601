import React, { useMemo } from 'react';
import { UseMutationResult } from '@tanstack/react-query';
import { ImportStringsProps, NoResponse, PublishJobType } from 'api';
import { useImportStrings, usePublishJob } from 'api/hooks';
import useJobDialog from 'components/hooks/useJobDialog';
import {
  StringSelection,
  useStringSelection,
} from 'components/hooks/useStringSelection';
import { IJob } from 'types/job';

type JobContextValue =
  | {
      stringSelection: StringSelection;
      importStringsQuery: UseMutationResult<
        NoResponse,
        unknown,
        ImportStringsProps
      >;
      publishJobQuery: UseMutationResult<IJob, unknown, PublishJobType>;
      jobStatusDialog: {
        open: boolean;
        openDialog: () => void;
        closeDialog: () => void;
      };
    }
  | undefined;

const JobContext = React.createContext<JobContextValue>(undefined);

export const useJobContext = () => {
  const context = React.useContext(JobContext);
  if (!context) {
    throw new Error(
      'useJobContext needs to be called within a JobContextProvider',
    );
  }
  return context;
};

export const JobContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const importStringsQuery = useImportStrings();
  const publishJobQuery = usePublishJob();
  const stringSelection = useStringSelection();
  const jobStatusDialog = useJobDialog();

  const { open: jobStatusDialogOpen } = jobStatusDialog;
  const { removingStrings, stopRemovingStrings } = stringSelection;

  React.useEffect(() => {
    if (jobStatusDialogOpen && removingStrings) {
      stopRemovingStrings();
    }
  }, [jobStatusDialogOpen, removingStrings, stopRemovingStrings]);

  const value = useMemo(
    () => ({
      importStringsQuery,
      publishJobQuery,
      stringSelection,
      jobStatusDialog,
    }),
    [importStringsQuery, publishJobQuery, stringSelection, jobStatusDialog],
  );
  return <JobContext.Provider value={value}>{children}</JobContext.Provider>;
};
