import React from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

const EmptyList = () => {
  return (
    <Paper
      sx={(theme) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: theme.spacing(5),
        marginTop: theme.spacing(2),
        padding: theme.spacing(2),
      })}
      elevation={0}
      data-testid="empty-list"
    >
      <Typography>There are no strings to display.</Typography>
    </Paper>
  );
};

export default EmptyList;
