export type GrammarContext =
  | 'NaN'
  | 'MaM'
  | 'FaF'
  | 'NaF'
  | 'NaM'
  | 'FaN'
  | 'MaN'
  | 'MaF'
  | 'FaM';

export const GRAMMAR_CONTEXTS: GrammarContext[] = [
  'NaN',
  'MaM',
  'FaF',
  'NaF',
  'NaM',
  'FaN',
  'MaN',
  'MaF',
  'FaM',
];
