import { useState, useEffect, useCallback } from 'react';

const DEFAULT_FONT_SIZE = 16;
const DEFAULT_FONT_NAME = 'Open Sans';
const DEFAULT_CONTAINER_WIDTH = 350;
const EXTRA_SLICER_OFFSET = 3;
const MIDDLE_TEXT = '...';

const useMiddleTruncateText = (text: string, prefixText = '') => {
  const [truncated, setTruncated] = useState<string>(text);
  const [outerContainerRef, setOuterContainerRef] =
    useState<HTMLElement | null>(null);
  let fontSize = DEFAULT_FONT_SIZE;
  let textWidth =
    text.length > 50 ? DEFAULT_CONTAINER_WIDTH * 2 : DEFAULT_CONTAINER_WIDTH;

  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  if (context) {
    if (!Number.isNaN(parseInt(getComputedStyle(document.body).fontSize, 10))) {
      fontSize = parseInt(getComputedStyle(document.body).fontSize, 10);
    }
    context.font = `${fontSize}px ${DEFAULT_FONT_NAME}`;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    textWidth = context!.measureText(`${prefixText}${text}`).width;
  }

  const getTruncatedText = useCallback(() => {
    if (!outerContainerRef) return;
    const containerWidth =
      outerContainerRef?.offsetWidth || DEFAULT_CONTAINER_WIDTH;
    if (containerWidth < textWidth) {
      const slicer =
        Math.floor(Math.abs(containerWidth - textWidth) / fontSize) +
        EXTRA_SLICER_OFFSET;
      const left = text.slice(0, text.length / 2 - slicer);
      const right = text.slice(text.length / 2 + slicer, text.length);
      setTruncated(`${left}${MIDDLE_TEXT}${right}`);
    } else if (truncated.indexOf(text) <= 0) {
      setTruncated(text);
    }
  }, [fontSize, outerContainerRef, text, textWidth, truncated]);

  useEffect(() => {
    window.addEventListener('resize', getTruncatedText);
    getTruncatedText();

    return () => {
      window.removeEventListener('resize', getTruncatedText);
    };
  }, [getTruncatedText]);

  return {
    truncated,
    setOuterContainerRef,
  };
};

export default useMiddleTruncateText;
