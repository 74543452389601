import { IStatus } from 'types/status';

export type JobStatus =
  | 'DRAFT'
  | 'AWAITING_QUOTE'
  | 'WITH_TRANSLATOR'
  | 'IN_REVIEW'
  | 'PENDING_PUBLISH'
  | 'PUBLISHED'
  | 'CANCELLED';

export enum EnumJobStatus {
  DRAFT = 'DRAFT',
  AWAITING_QUOTE = 'AWAITING_QUOTE',
  WITH_TRANSLATOR = 'WITH_TRANSLATOR',
  IN_REVIEW = 'IN_REVIEW',
  PENDING_PUBLISH = 'PENDING_PUBLISH',
  PUBLISHED = 'PUBLISHED',
  CANCELLED = 'CANCELLED',
}

export const TRANSLATION_JOB_STATUSES: { [key in JobStatus]: IStatus } = {
  DRAFT: {
    id: '0',
    value: EnumJobStatus.DRAFT,
    displayName: 'Draft',
  },
  AWAITING_QUOTE: {
    id: '1',
    value: EnumJobStatus.AWAITING_QUOTE,
    displayName: 'Awaiting Quote',
  },
  WITH_TRANSLATOR: {
    id: '2',
    value: EnumJobStatus.WITH_TRANSLATOR,
    displayName: 'With Translator',
  },
  IN_REVIEW: {
    id: '3',
    value: EnumJobStatus.IN_REVIEW,
    displayName: 'In Review',
  },
  PENDING_PUBLISH: {
    id: '4',
    value: EnumJobStatus.PENDING_PUBLISH,
    displayName: 'Pending Publish',
  },
  PUBLISHED: {
    id: '5',
    value: EnumJobStatus.PUBLISHED,
    displayName: 'Live',
  },
  CANCELLED: {
    id: '6',
    value: EnumJobStatus.CANCELLED,
    displayName: 'Cancelled',
  },
};

export const TRANSLATION_JOB_STATUS_ORDER: IStatus[] = [
  TRANSLATION_JOB_STATUSES.DRAFT,
  TRANSLATION_JOB_STATUSES.AWAITING_QUOTE,
  TRANSLATION_JOB_STATUSES.WITH_TRANSLATOR,
  TRANSLATION_JOB_STATUSES.IN_REVIEW,
  TRANSLATION_JOB_STATUSES.PENDING_PUBLISH,
  TRANSLATION_JOB_STATUSES.PUBLISHED,
  TRANSLATION_JOB_STATUSES.CANCELLED,
];

export const getAvailableStatusTransitions = (
  currentStatus: keyof typeof EnumJobStatus,
): IStatus[] => {
  switch (currentStatus) {
    case EnumJobStatus.DRAFT:
      return [
        TRANSLATION_JOB_STATUSES.AWAITING_QUOTE,
        TRANSLATION_JOB_STATUSES.WITH_TRANSLATOR,
        TRANSLATION_JOB_STATUSES.CANCELLED,
      ];
    case EnumJobStatus.AWAITING_QUOTE:
      return [
        TRANSLATION_JOB_STATUSES.DRAFT,
        TRANSLATION_JOB_STATUSES.WITH_TRANSLATOR,
        TRANSLATION_JOB_STATUSES.CANCELLED,
      ];
    case EnumJobStatus.WITH_TRANSLATOR:
      return [
        TRANSLATION_JOB_STATUSES.IN_REVIEW,
        TRANSLATION_JOB_STATUSES.CANCELLED,
      ];
    case EnumJobStatus.IN_REVIEW:
      return [
        TRANSLATION_JOB_STATUSES.WITH_TRANSLATOR,
        TRANSLATION_JOB_STATUSES.CANCELLED,
      ];
    case EnumJobStatus.PENDING_PUBLISH:
      return [TRANSLATION_JOB_STATUSES.CANCELLED];
    default:
      return [];
  }
};
