import React from 'react';
import { SvgComponentProps } from 'components/Svgs/types';

const SvgExpand = ({ title, ...props }: SvgComponentProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    width="17"
    height="17"
    viewBox="0 0 17 17"
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <path fill="#fff" stroke="#B3B3B3" d="M.5 1.352h15v14.955H.5V1.351Z" />
    <path
      stroke="#1673A3"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M4 8.828h8M8 4.84v7.977"
    />
  </svg>
);

export default SvgExpand;
