import React from 'react';
import { Alert, AlertColor, Snackbar } from '@mui/material';

const JobSnackbar = ({
  open,
  reset,
  severity,
  children,
}: {
  open: boolean;
  reset: () => void;
  severity?: AlertColor;
  children: React.ReactNode;
}) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={reset}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <Alert elevation={6} variant="filled" onClose={reset} severity={severity}>
        {children}
      </Alert>
    </Snackbar>
  );
};

export default JobSnackbar;
