import React from 'react';
import {
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  styled,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

export type DialogueButtonType = {
  title: string;
  handleOnClick: () => void;
  buttonProps?: ButtonProps;
};

type PropsType = {
  dialogTitle: string;
  dialogContentText?: string;
  dialogContentSubText?: string;
  isOpen: boolean;
  onClose: () => void;
  buttons: DialogueButtonType[];
  dialogGraphic?: React.ReactNode;
};

const StyledDiv = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const ConfirmationDialog = ({
  dialogTitle,
  dialogContentText,
  dialogContentSubText,
  isOpen,
  onClose,
  buttons,
  dialogGraphic,
}: PropsType) => {
  return (
    <Dialog open={isOpen}>
      <DialogTitle>
        <StyledDiv>
          {dialogTitle}
          <span />
          <IconButton
            sx={{ marginLeft: '50px', padding: '0', borderRadius: '2px' }}
            onClick={onClose}
            size="large"
            data-testid="close-button"
          >
            <ClearIcon
              sx={(theme) => ({
                width: '30px',
                height: '30px',
                color: theme.palette.background.paper,
                backgroundColor: theme.palette.error.main,
              })}
            />
          </IconButton>
        </StyledDiv>
      </DialogTitle>
      {dialogGraphic && (
        <Grid container justifyContent="center">
          <Grid item>{dialogGraphic}</Grid>
        </Grid>
      )}
      {dialogContentText && (
        <DialogContent>
          <DialogContentText>{dialogContentText}</DialogContentText>
          {dialogContentSubText && (
            <DialogContentText>{dialogContentSubText}</DialogContentText>
          )}
        </DialogContent>
      )}
      <DialogActions>
        {buttons.map((button) => (
          <Button
            key={button.title}
            onClick={button.handleOnClick}
            {...button.buttonProps}
            fullWidth
          >
            {button.title}
          </Button>
        ))}
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
