import * as React from 'react';
import { Grid, styled } from '@mui/material';
import { DatePickerV2, Text } from '@insights-ltd/design-library/components';
import { IDeadlineFilter } from 'types/job';
import { dateFromISOToLocal, fromLocalFormatToDateTime } from 'utils/utils';
import { DATE_GLOBAL_FORMAT } from 'variables';

interface IPropsType {
  deadline: IDeadlineFilter;
  onDeadlineChange: (
    date: string | undefined,
    type: 'startDate' | 'endDate',
  ) => void;
}

const StyledText = styled(Text)(({ theme }) => ({
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  color: theme.palette.text.secondary,
}));

const DeadlineFilter = ({ deadline, onDeadlineChange }: IPropsType) => {
  const startDate = deadline.startDate
    ? fromLocalFormatToDateTime(deadline.startDate)
    : null;
  const endDate = deadline.endDate
    ? fromLocalFormatToDateTime(deadline.endDate)
    : null;
  return (
    <Grid container justifyContent="center">
      <div>
        <DatePickerV2
          disablePast
          format={DATE_GLOBAL_FORMAT}
          labelText="Date From"
          maxDate={startDate ? endDate : undefined}
          onChange={(date: any) =>
            onDeadlineChange(dateFromISOToLocal(date?.toISO()), 'startDate')
          }
          sx={{
            width: '100%',
          }}
          value={startDate}
        />
      </div>
      <StyledText variant="body">TO</StyledText>
      <div>
        <DatePickerV2
          disablePast
          format={DATE_GLOBAL_FORMAT}
          labelText="Date To"
          minDate={endDate ? startDate : undefined}
          onChange={(date: any) =>
            onDeadlineChange(dateFromISOToLocal(date?.toISO()), 'endDate')
          }
          sx={{
            width: '100%',
          }}
          value={endDate}
        />
      </div>
    </Grid>
  );
};

export default DeadlineFilter;
