import * as React from 'react';
import { H2 } from '@insights-ltd/design-library/components';
import { styled } from '@mui/material';

type Props = {
  text: string;
  count: number;
};

type StyledSpanProps = {
  count: number;
};

const StyledSpan = styled('span', {
  shouldForwardProp: (prop) => prop !== 'count',
})<StyledSpanProps>(({ count, theme }) => ({
  padding: `0 ${theme.spacing(1)}`,
  color: theme.palette.background.paper,
  borderRadius: '4px',
  backgroundColor:
    count > 0 ? theme.palette.pink.main : theme.palette.blue.main,
}));

const EntriesCounter = ({ text, count }: Props) => {
  return (
    <H2 variant="h3">
      {`${text} `}{' '}
      <StyledSpan aria-label="Label entries count" count={count}>
        {count}
      </StyledSpan>
    </H2>
  );
};

export default EntriesCounter;
