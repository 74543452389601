import React from 'react';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';

export interface ILinkPropsType {
  children: React.ReactNode;
  route: string;
}

interface IPropsType {
  breadcrumbs: ILinkPropsType[];
}

const Navigation = ({ breadcrumbs }: IPropsType) => (
  <Breadcrumbs
    separator="/"
    aria-label="breadcrumb"
    sx={(theme) => ({
      color: '#000',
      '* li': {
        color: theme.palette.blue.main,
      },
      '* a, * a:visited': {
        color: theme.palette.blue.main,
        textTransform: 'capitalize',
      },
      '* a:hover': {
        color: theme.palette.blue.dark,
      },
    })}
  >
    {breadcrumbs.map((curr) =>
      curr.route !== '' ? (
        <Link color="inherit" to={curr.route} key={curr.route}>
          {curr.children}
        </Link>
      ) : (
        <Typography
          key={curr.route}
          sx={(theme) => ({
            color: '#000',
            '* li': {
              color: theme.palette.blue.main,
            },
            '* a, * a:visited': {
              color: theme.palette.blue.main,
              textTransform: 'capitalize',
            },
            '* a:hover': {
              color: theme.palette.blue.dark,
            },
          })}
        >
          {curr.children}
        </Typography>
      ),
    )}
  </Breadcrumbs>
);

export default Navigation;
