import * as React from 'react';
import { Button, Menu, MenuItem } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

interface IPropsType {
  doLogout: () => Promise<void>;
  username?: string;
}

const AccountWidget = ({ doLogout, username = '' }: IPropsType) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const onClick = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const onLogoutClick = () => {
    doLogout().then();
    handleClose();
  };

  return (
    <div>
      <Button
        startIcon={<PersonIcon />}
        endIcon={<KeyboardArrowDownIcon />}
        aria-owns="account-menu"
        aria-haspopup="true"
        aria-label="Account Button"
        onClick={onClick}
      >
        {username}
      </Button>
      <Menu
        id="account-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <MenuItem onClick={onLogoutClick}>Sign Out</MenuItem>
      </Menu>
    </div>
  );
};

export default AccountWidget;
