export const DATADOG_RUM_CONFIGS = [
  {
    hostname: 'test.localisation.insights.com',
    applicationId: '80305fb3-47d6-4041-b78c-e365c772a81d',
    clientToken: 'puba7e8751e6ab24fb4a6c0d918b9662d6a',
  },
  {
    hostname: 'demo.localisation.insights.com',
    applicationId: '9183c6e2-b867-41e1-90dd-f83f3caaf3ea',
    clientToken: 'pubaf98cdbda070a11b08dd63802bf63cab',
  },
  {
    hostname: 'localisation.insights.com',
    applicationId: '2fd02572-cd0e-4051-8bea-aeae0e8627bb',
    clientToken: 'puba6cc4b16744d537198f95381c50b3f6b',
  },
];
