const owners = [
  {
    id: '0',
    name: 'Rachel',
  },
  {
    id: '1',
    name: 'Steve',
  },
  {
    id: '2',
    name: 'Alex',
  },
];

export default owners;
