import Box from '@mui/material/Box';
import React, { useEffect } from 'react';
import {
  QueryObserverPendingResult,
  QueryObserverSuccessResult,
} from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { AlertColor, Container } from '@mui/material';
import { useGetJob, useGetJobTranslations } from 'api/hooks';
import { JobRouteParams } from 'types';
import { FullScreenError, FullScreenSpinner } from 'components/FullScreen';
import useJobDialog from 'components/hooks/useJobDialog';
import BottomBar from 'components/Strings/BottomBar';
import { StringsResponseList } from 'types/api';
import { IJob } from 'types/job';
import { useFilterContext } from 'components/FilterProvider/FilterProvider';
import RemoveStringsDialogs from './components/RemoveStringsDialogs';
import JobSnackbar from './components/JobSnackbar';
import JobStatusDialog from './components/JobStatusDialog';
import { JobContextProvider, useJobContext } from './hooks/jobContext';
import LoadedJob from './components/LoadedJob';

const ImportStringsSnackbar = () => {
  const {
    importStringsQuery: {
      status: statusImportStrings,
      reset: resetImportStrings,
    },
  } = useJobContext();
  return (
    <JobSnackbar
      open={['error', 'success'].includes(statusImportStrings)}
      reset={resetImportStrings}
      severity={
        ['error', 'success'].includes(statusImportStrings)
          ? (statusImportStrings as AlertColor)
          : undefined
      }
    >
      {statusImportStrings === 'success'
        ? 'Import successful.'
        : 'Failed to import strings for job. Please try again later.'}
    </JobSnackbar>
  );
};

const PublishJobSnackbar = () => {
  const {
    publishJobQuery: { status: statusPublishJob, reset: resetPublishJob },
  } = useJobContext();
  return (
    <JobSnackbar
      open={['error', 'success'].includes(statusPublishJob)}
      reset={resetPublishJob}
      severity={
        ['error', 'success'].includes(statusPublishJob)
          ? (statusPublishJob as AlertColor)
          : undefined
      }
    >
      {statusPublishJob === 'success'
        ? 'Job was successfully published.'
        : 'There was an error publishing this job. Please try again later.'}
    </JobSnackbar>
  );
};

const StringSelectionBottomBar = ({ onProceed }: { onProceed: () => void }) => {
  const {
    stringSelection: { selectedStrings, stopRemovingStrings },
  } = useJobContext();
  if (selectedStrings && selectedStrings.length > 0) {
    return (
      <Box marginTop={7}>
        <BottomBar
          stringCount={selectedStrings.length}
          onProceed={onProceed}
          onCancel={stopRemovingStrings}
          variant="remove"
        />
      </Box>
    );
  }
  return null;
};

const JobDialogs = ({
  jobQuery,
  translationsQuery,
  children,
}: {
  jobQuery: QueryObserverSuccessResult<IJob>;
  translationsQuery:
    | QueryObserverSuccessResult<StringsResponseList, Error>
    | QueryObserverPendingResult<StringsResponseList, Error>;
  children: React.ReactNode;
}) => {
  const {
    open: confirmationDialogOpen,
    openDialog: openConfirmationDialog,
    closeDialog: closeConfirmationDialog,
  } = useJobDialog();

  const job = jobQuery.data;

  const jobDataFetching = jobQuery.isFetching || translationsQuery.isFetching;
  return (
    <Container maxWidth="lg">
      {jobDataFetching && <FullScreenSpinner message="Loading..." />}
      {!jobDataFetching && children}
      <StringSelectionBottomBar onProceed={openConfirmationDialog} />
      <ImportStringsSnackbar />
      <PublishJobSnackbar />
      <RemoveStringsDialogs
        job={job}
        open={confirmationDialogOpen}
        onClose={closeConfirmationDialog}
      />
      <JobStatusDialog job={job} />
    </Container>
  );
};

const Job = () => {
  const { jobId } = useParams<JobRouteParams>();
  const {
    sessionData: { lastJobOpened } = { lastJobOpened: '' },
    setFilterState,
  } = useFilterContext();

  useEffect(() => {
    if (lastJobOpened !== jobId) {
      setFilterState({ lastJobOpened: jobId });
    }
  }, [jobId, setFilterState, lastJobOpened]);

  const jobQuery = useGetJob(jobId || '');
  const jobHasTranslations =
    jobQuery.isSuccess &&
    jobQuery.data.translations &&
    jobQuery.data.translations.length > 0;
  const translationsQuery = useGetJobTranslations(jobId || '', {
    enabled: jobHasTranslations,
  });

  if (jobQuery.isPending || translationsQuery.isLoading) {
    return <FullScreenSpinner message="Loading..." />;
  }

  if (jobQuery.isError || translationsQuery.isError) {
    return (
      <FullScreenError message="There was an error finding the job, please try refresh the page." />
    );
  }

  return (
    <JobContextProvider>
      <JobDialogs jobQuery={jobQuery} translationsQuery={translationsQuery}>
        <LoadedJob job={jobQuery.data} translations={translationsQuery.data} />
      </JobDialogs>
    </JobContextProvider>
  );
};

export default Job;
