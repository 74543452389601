import React from 'react';
import { SvgComponentProps } from './types';

const SvgConfirmIllustration = ({ title, ...props }: SvgComponentProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    width="216"
    height="169"
    viewBox="0 0 216 169"
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <g opacity={0.75}>
      <path
        fill="#B63230"
        d="M24.197 84.084c-.128 0-.247-.08-.336-.227-.296-.514-1.312-2.094-1.846-5.767-2.243-.524-2.887-1.383-3.213-1.679a.547.547 0 0 1-.158-.504c.059-.316-.226-1.097 3.55-2.075 1.096-7.04 1.865-6.743 2.122-6.812.03-.01.06-.01.08-.01.128 0 .246.08.335.227.316.544.986 2.487 1.57 6.595 3.755.978 3.589 1.76 3.648 2.085a.582.582 0 0 1-.158.504c-.326.296-1.024 1.145-3.297 1.669-1.008 6.27-1.95 5.925-2.218 6.004-.03-.01-.059-.01-.079-.01Z"
      />
      <path
        fill="#0E567B"
        d="M188.897 89.726a.326.326 0 0 1-.267-.148c-.227-.326-.543-1.61-.968-3.94-1.759-.326-2.738-.583-2.995-.77a.335.335 0 0 1-.128-.316c.039-.198.089-.435 3.054-1.047.859-4.454 1.195-4.523 1.393-4.572.02 0 .039-.01.069-.01.099 0 .198.05.267.148.247.346.603 1.837 1.057 4.434 2.945.612 2.995.849 3.034 1.047.03.118-.02.236-.128.316-.257.187-1.245.444-3.024.77-.791 3.96-1.087 4.029-1.295 4.068-.029.01-.049.02-.069.02Z"
      />
      <path
        fill="#8AA512"
        d="M88.478 16.491a.194.194 0 0 1-.168-.089c-.149-.207-.529-.826-.796-2.327-1.136-.217-1.596-.596-1.764-.714a.193.193 0 0 1-.079-.208c.03-.128-.074-.522 1.843-.927.554-2.873.904-2.677 1.033-2.706h.04c.069 0 .128.03.167.088.158.228.522.94.818 2.618 1.897.395 1.801.79 1.82.918a.193.193 0 0 1-.078.207c-.168.118-.586.371-1.742.589-.514 2.557-.916 2.512-1.055 2.541-.01 0-.03.01-.04.01Z"
      />
      <path
        fill="#B63230"
        d="m150.217 25.204 2.46-2.458 6.128 6.124-2.46 2.458-6.128-6.124Z"
      />
      <path
        fill="#FFBF19"
        d="m209.376 30.984-2.171 1.958-4.873-5.396 2.172-1.958 4.872 5.396Z"
      />
      <path
        fill="#B63230"
        d="m193.454 124.844 2.533 1.461-3.637 6.294-2.533-1.461 3.637-6.294Z"
      />
      <path
        fill="#0E567B"
        d="m33.36 32.974-2.46 2.458-6.128-6.124 2.46-2.457 6.128 6.123Z"
      />
      <path
        fill="#8AA512"
        d="m1.098 143.793.002-3.477 8.663-.003-.001 3.477-8.664.003Z"
      />
      <path
        fill="#0E567B"
        d="m32.088 152.052 3.399.731-1.828 8.47-3.4-.732 1.829-8.469ZM196.67 156.177l2.911 1.901-4.742 7.252-2.911-1.901 4.742-7.252Z"
      />
      <path
        fill="#8AA512"
        d="m207.635 76.296-2.21-2.684 6.692-5.503 2.211 2.684-6.693 5.503Z"
      />
      <path
        fill="#FCC94F"
        d="m57.49 39.994 1.828 2.024-5.033 4.538-1.827-2.024 5.032-4.538ZM6.859 103.456l1.008 2.533-6.298 2.505-1.009-2.532 6.299-2.506ZM118.256.108l1.827 2.023-5.033 4.538-1.827-2.023 5.033-4.538Z"
      />
    </g>
    <path
      fill="#EEEEE9"
      d="M107 169c32.585 0 59-26.415 59-59s-26.415-59-59-59-59 26.415-59 59 26.415 59 59 59Z"
    />
    <path
      fill="#1673A3"
      fillRule="evenodd"
      d="m175.354 71.119-12.611-12.465a2.276 2.276 0 0 0-1.599-.654c-.6 0-1.176.235-1.599.654l-53.265 52.53a2.274 2.274 0 0 1-1.599.654c-.6 0-1.175-.235-1.599-.654L90.471 98.719c-.424-.418-1-.654-1.6-.654-.6 0-1.175.236-1.598.654L74.66 111.184a2.203 2.203 0 0 0 0 3.147l28.421 28.015c.424.419.999.654 1.599.654s1.175-.235 1.599-.654l69.074-68.111a2.203 2.203 0 0 0 0-3.116Z"
      clipRule="evenodd"
    />
  </svg>
);

export default SvgConfirmIllustration;
