import React, { ReactNode } from 'react';
import { AppBar, Container, Grid, styled } from '@mui/material';
import InsightsLogo from 'components/Svgs/InsightsLogo';

type Props = {
  children: ReactNode;
};

const StyledInsightsLogo = styled(InsightsLogo)(({ theme }) => ({
  width: '128px',
  height: '34px',
  marginRight: '6rem',
  fill: theme.palette.blue.main,
}));

const HeaderLayout = ({ children }: Props) => {
  return (
    <AppBar
      position="static"
      elevation={0}
      sx={(theme) => ({
        backgroundColor: theme.palette.background.paper,
        borderBottom: `1px solid ${theme.palette.info.dark}`,
      })}
    >
      <Container maxWidth="lg">
        <Grid
          container
          alignItems="center"
          sx={(theme) => ({ padding: `${theme.spacing(2)} 0` })}
        >
          <StyledInsightsLogo />
          <Grid item xs>
            {children}
          </Grid>
        </Grid>
      </Container>
    </AppBar>
  );
};

export default HeaderLayout;
