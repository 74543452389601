export type JobDialectOption = {
  id: string;
  title: string;
  dialect: string;
};

export const languages: JobDialectOption[] = [
  {
    title: 'Afrikaans',
    dialect: 'af-ZA',
  },
  {
    title: 'Bahasa (Indonesia)',
    dialect: 'id-ID',
  },
  {
    title: 'Bulgarian',
    dialect: 'bg-BG',
  },
  {
    title: 'Chinese (Simplified)',
    dialect: 'zh-CN',
  },
  {
    title: 'Chinese (Traditional)',
    dialect: 'zh-HK',
  },
  {
    title: 'Croatian',
    dialect: 'hr-HR',
  },
  {
    title: 'Czech',
    dialect: 'cs-CZ',
  },
  {
    title: 'Danish',
    dialect: 'da-DK',
  },
  {
    title: 'Dutch',
    dialect: 'nl-NL',
  },
  {
    title: 'English',
    dialect: 'en-GB',
  },
  {
    title: 'English (South Africa)',
    dialect: 'en-ZA',
  },
  {
    title: 'Estonian',
    dialect: 'et-EE',
  },
  {
    title: 'Finnish',
    dialect: 'fi-FI',
  },
  {
    title: 'French (Canada)',
    dialect: 'fr-CA',
  },
  {
    title: 'French (France)',
    dialect: 'fr-FR',
  },
  {
    title: 'Georgian',
    dialect: 'ka-GE',
  },
  {
    title: 'German',
    dialect: 'de-DE',
  },
  {
    title: 'Greek',
    dialect: 'el-GR',
  },
  {
    title: 'Hungarian',
    dialect: 'hu-HU',
  },
  {
    title: 'Icelandic',
    dialect: 'is-IS',
  },
  {
    title: 'Italian',
    dialect: 'it-IT',
  },
  {
    title: 'Japanese',
    dialect: 'ja-JP',
  },
  {
    title: 'Korean',
    dialect: 'ko-KR',
  },
  {
    title: 'Lithuanian',
    dialect: 'lt-LT',
  },
  {
    title: 'Norwegian',
    dialect: 'nb-NO',
  },
  {
    title: 'Polish',
    dialect: 'pl-PL',
  },
  {
    title: 'Portuguese (Brazil)',
    dialect: 'pt-BR',
  },
  {
    title: 'Portuguese (Portugal)',
    dialect: 'pt-PT',
  },
  {
    title: 'Romanian',
    dialect: 'ro-RO',
  },
  {
    title: 'Russian',
    dialect: 'ru-RU',
  },
  {
    title: 'Serbian (Latin)',
    dialect: 'sr-RS',
  },
  {
    title: 'Slovak',
    dialect: 'sk-SK',
  },
  {
    title: 'Slovenian',
    dialect: 'sl-SI',
  },
  {
    title: 'Spanish (Mexico)',
    dialect: 'es-MX',
  },
  {
    title: 'Spanish (Spain)',
    dialect: 'es-ES',
  },
  {
    title: 'Swedish',
    dialect: 'sv-SE',
  },
  {
    title: 'Turkish',
    dialect: 'tr-TR',
  },
  {
    title: 'Ukrainian',
    dialect: 'uk-UA',
  },
  {
    title: 'Vietnamese',
    dialect: 'vi-VN',
  },
].map((item, idx) => ({ ...item, id: idx.toString() }));

export const languagesWithoutEnglish = languages.filter(
  ({ dialect }) => dialect !== 'en-GB',
);
