import React from 'react';
import { Button, ButtonProps } from '@mui/material';

export const JobButton = <C extends React.ElementType>({
  className,
  children,
  variant,
  ...props
}: ButtonProps<C, { component?: C }>) => {
  return (
    <Button
      className={className}
      sx={() =>
        variant === 'outlined'
          ? {
              border: '2px solid',
              '&:hover, &:focus, &:disabled': {
                border: '2px solid',
              },
            }
          : {}
      }
      variant={variant}
      {...props}
    >
      {children}
    </Button>
  );
};

export default JobButton;
