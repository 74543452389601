import React from 'react';
import { Divider } from '@mui/material';
import Grid from '@mui/material/Grid';
import CheckIcon from '@mui/icons-material/CheckCircle';
import CrossIcon from '@mui/icons-material/Cancel';
import { Dialog, P } from '@insights-ltd/design-library/components';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import { IDialects } from 'variables';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';

type BulkJobResult = {
  dialect: keyof IDialects;
  success: boolean;
  jobId: string;
};

type Props = {
  open: boolean;
  onClose: () => void;
  results: BulkJobResult[];
};

const StatusRow = ({
  error,
  dialect,
  jobId,
}: {
  error: boolean;
  dialect: keyof IDialects;
  jobId?: string;
}) => {
  const errorState = () => {
    if (!jobId) {
      return (
        <Grid container spacing={1}>
          <Grid item>
            <CrossIcon sx={(theme) => ({ color: theme.palette.error.main })} />
          </Grid>
          <Grid item>
            <P variant="body-bold">Job not created</P>
          </Grid>
        </Grid>
      );
    }
    return (
      <Grid container spacing={2}>
        <Grid item>
          {!error ? (
            <CheckIcon
              sx={(theme) => ({
                marginTop: theme.spacing(spacingSizeMap.XS),
                color: theme.palette.success.main,
              })}
            />
          ) : (
            <CheckIcon
              sx={(theme) => ({
                marginTop: theme.spacing(spacingSizeMap.XS),
                color: theme.palette.blue.main,
              })}
            />
          )}
        </Grid>
        <Grid item>
          <P variant="body-bold">
            {!error ? 'Job created' : 'Job created with no strings'}
          </P>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container>
      <Grid container justifyContent="space-between" item xs={12}>
        <Grid item>
          <P color="error" variant="body-bold">
            <Link target="_blank" component={RouterLink} to={`/jobs/${jobId}`}>
              {jobId}
            </Link>
          </P>
        </Grid>
        <Grid item>
          <P color="textSecondary" variant="body-bold">
            {dialect}
          </P>
        </Grid>
        <Grid item width={1000}>
          <>{errorState()}</>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider
          sx={(theme) => ({ marginTop: theme.spacing(spacingSizeMap.XS) })}
        />
      </Grid>
    </Grid>
  );
};

const BulkJobsStatusModal = ({ open, onClose, results }: Props) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      title="Error creating jobs"
      titleProps={{
        id: 'dialog-title',
      }}
      primaryButtonText="Okay"
      content={
        <Grid container spacing={2}>
          <Grid item>
            <P color="textSecondary">Job summary</P>
          </Grid>
          {results.map(({ dialect, success, jobId }) => (
            <Grid item key={dialect} xs={12}>
              <StatusRow error={!success} dialect={dialect} jobId={jobId} />
            </Grid>
          ))}
        </Grid>
      }
    />
  );
};

export default BulkJobsStatusModal;
