import { DateTime } from 'luxon';
import { dateFromLocalToISO, groupStringsByKey } from 'utils/utils';
import {
  IJobsResponse,
  ITranslationsResponse,
  StringsResponseList,
  TranslationKeys,
} from './types/api';
import { CreateJobType, EditJob, IJob, JobFilter } from './types/job';
import { IUser } from './types/auth';

export type RequestErrorBody = {
  errors: { code: string }[];
  message: string;
  status: number;
  ts: string;
};

export type NoResponse = Record<string, never>;

const httpRequest = async <T>(
  path: string,
  defaultValue: T,
  requestInit?: RequestInit,
): Promise<T> => {
  const response = await fetch(
    import.meta.env.VITE_API_PREFIX + path,
    requestInit,
  );

  if (response.status === 401) {
    window.location.assign('/oauth2/login');
    return Promise.resolve(defaultValue);
  }

  if (!response.ok) {
    const err: RequestErrorBody = await response.json();
    throw new Error(err.message);
  }

  return response.json();
};

const notImportantJob: IJob = {
  id: '',
  title: '',
  dialect: 'en-GB',
  status: 'DRAFT',
  translator: '',
  owner: '',
};

export const getJob = async (id: string): Promise<IJob> =>
  httpRequest(`/api/v1/jobs/${id}`, notImportantJob);

export const getJobTranslations = async (
  id: string,
): Promise<ITranslationsResponse> =>
  httpRequest<ITranslationsResponse>(`/api/v1/jobs/${id}/translations`, []);

export const getTranslations = async (
  dialect: string,
  search: string | undefined,
  keys: string[],
): Promise<StringsResponseList> => {
  const keyParam = keys.length
    ? { keys: keys.join(',') }
    : ({} as Record<string, string>);
  const params = { ...(search && { search }), dialect, ...keyParam };
  const queryParams = new URLSearchParams(params);

  const translations = await httpRequest<ITranslationsResponse>(
    `/api/v1/translations?${queryParams}`,
    [],
  );
  return groupStringsByKey(translations);
};

export const createJob = async (job: CreateJobType): Promise<IJob> =>
  httpRequest(`/api/v1/jobs`, notImportantJob, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(job),
  });

export const editJob = async (job: EditJob): Promise<NoResponse> =>
  httpRequest(
    `/api/v1/jobs/${job.id}`,
    {},
    {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(job),
    },
  );

export type GetJobsParams = {
  dialect?: string;
  statuses?: string[];
  startDate?: string;
  endDate?: string;
  owner?: string;
  translator?: string;
  requester?: string;
};

const removeEmptyParams = (obj: Record<string, string>) =>
  Object.entries(obj).reduce(
    (acc, [key, value]) => (value ? { ...acc, [key]: value } : acc),
    {},
  );

export const getFilteredJobs = async ({
  dialect = '',
  statuses = [],
  startDate = '',
  endDate = '',
  owner = '',
  translator = '',
  requester = '',
}: GetJobsParams = {}): Promise<IJobsResponse> => {
  const status = statuses.join(',');
  const params = removeEmptyParams({
    dialect,
    status,
    deadlineStartDate: dateFromLocalToISO(startDate) || '',
    deadlineEndDate: dateFromLocalToISO(endDate) || '',
    owner,
    translator,
    requester,
  });

  const queryParams = new URLSearchParams(params);
  return httpRequest(`/api/v1/jobs?${queryParams}`, []);
};

export const getJobs = async ({
  dialect,
  status,
  deadline,
}: JobFilter): Promise<IJobsResponse> => {
  const params = new URLSearchParams();
  if (dialect) params.append('dialect', dialect);
  if (status && Object.keys(status).length > 0) {
    params.append('status', Object.keys(status).join());
  }

  if (deadline.startDate) {
    params.append(
      'deadlineStartDate',
      DateTime.fromISO(deadline.startDate).toISO() ?? '',
    );
  }
  if (deadline.endDate) {
    params.append(
      'deadlineEndDate',
      DateTime.fromISO(deadline.endDate).toISO() ?? '',
    );
  }

  return httpRequest(`/api/v1/jobs?${params}`, []);
};

type AddStringsType = {
  jobId: string;
  keys: string[];
};

export const addStringsToJob = async ({
  jobId,
  keys,
}: AddStringsType): Promise<NoResponse> =>
  httpRequest(
    `/api/v1/jobs/${jobId}/translations`,
    {},
    {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(keys),
    },
  );

const logoutResponse = {
  url: '',
};

export const logout = async () => {
  const body = await httpRequest('/api/v1/logout', logoutResponse, {
    method: 'POST',
  });

  if (body.url) {
    window.location.assign(body.url);
  }
};

export const login = async (): Promise<IUser | undefined> =>
  httpRequest('/api/v1/user', { username: '' });

export type ImportStringsProps = {
  jobId: string;
  file: File;
};

export const importStrings = async ({
  jobId,
  file,
}: ImportStringsProps): Promise<NoResponse> =>
  httpRequest(
    `/api/v1/translation-files/${jobId}`,
    {},
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'text/csv',
      },
      body: file,
    },
  );

export type PublishJobType = { jobId: string };
export const publishJob = async ({ jobId }: PublishJobType): Promise<IJob> => {
  return httpRequest(`/api/v1/jobs/${jobId}`, notImportantJob, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
};

export const getTranslationKeys = async (): Promise<TranslationKeys> =>
  httpRequest('/api/v1/translations/keys', {});

export const getExportUrl = (jobId: string) => {
  return `${import.meta.env.VITE_API_PREFIX}/api/v1/translation-files/${jobId}`;
};
