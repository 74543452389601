import * as React from 'react';

import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { Typography } from '@mui/material';

const EmptyTable = () => {
  return (
    <TableBody>
      <TableRow>
        <TableCell colSpan={9} align="center">
          <Typography variant="h6">There are no jobs to display.</Typography>
        </TableCell>
      </TableRow>
    </TableBody>
  );
};

export default EmptyTable;
