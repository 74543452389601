import * as React from 'react';
import { ChangeEvent } from 'react';
import { Checkbox, FormGroup, FormControlLabel, styled } from '@mui/material';
import {
  TRANSLATION_JOB_STATUSES,
  TRANSLATION_JOB_STATUS_ORDER,
} from 'variables';
import { IStatusFilter } from 'types/status';
import { getTheme } from '@insights-ltd/design-library/themes';

const localTheme = getTheme();

const CheckboxWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

interface IPropsType {
  selectedStatus: IStatusFilter;
  onStatusChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const StatusFilter = ({ selectedStatus, onStatusChange }: IPropsType) => {
  return (
    <FormGroup
      sx={{
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      <CheckboxWrapper>
        {TRANSLATION_JOB_STATUS_ORDER.map((status) => {
          let selectedBackground;
          let selectedBorder;
          let selectedColor;

          switch (status) {
            case TRANSLATION_JOB_STATUSES.DRAFT:
              selectedBackground = localTheme.brandColours.lightBlue;
              selectedColor = localTheme.brandColours.blue;
              break;
            case TRANSLATION_JOB_STATUSES.AWAITING_QUOTE:
              selectedBackground = localTheme.brandColours.lightOrange;
              selectedColor = localTheme.brandColours.orange;
              break;
            case TRANSLATION_JOB_STATUSES.WITH_TRANSLATOR:
              selectedBorder = `1px solid ${localTheme.brandColours.orange}`;
              selectedColor = localTheme.brandColours.orange;
              break;
            case TRANSLATION_JOB_STATUSES.IN_REVIEW:
              selectedBorder = `1px solid ${localTheme.brandColours.green}`;
              selectedColor = localTheme.brandColours.green;
              break;
            case TRANSLATION_JOB_STATUSES.PENDING_PUBLISH:
              selectedBorder = `1px solid ${localTheme.brandColours.blue}`;
              selectedColor = localTheme.brandColours.blue;
              break;
            case TRANSLATION_JOB_STATUSES.PUBLISHED:
              selectedBackground = localTheme.brandColours.lightGreen;
              selectedColor = localTheme.brandColours.green;
              break;
            case TRANSLATION_JOB_STATUSES.CANCELLED:
              selectedBorder = `1px solid ${localTheme.validationColours.error}`;
              selectedColor = localTheme.validationColours.error;
              break;
            default:
              break;
          }

          return (
            <div key={status.id}>
              <FormControlLabel
                sx={{
                  marginTop: '8px',
                  marginBottom: '8px',
                  paddingRight: '10px',
                  fontWeight: 'bold',
                  borderRadius: '20px',
                  background: selectedBackground,
                  border: selectedBorder,
                  color: selectedColor,
                }}
                control={
                  <Checkbox
                    onChange={onStatusChange}
                    checked={!!selectedStatus[status.value]}
                    color="primary"
                    name={status.value}
                  />
                }
                label={status.displayName}
              />
            </div>
          );
        })}
      </CheckboxWrapper>
    </FormGroup>
  );
};

export default StatusFilter;
