import { useState } from 'react';

type ExpandedStatus = 'NO_TRUNCATION' | 'COLLAPSED' | 'EXPANDED';

export const combineExpandedStatuses = (
  status1: ExpandedStatus,
  status2: ExpandedStatus,
): ExpandedStatus => {
  if (status1 === 'EXPANDED' || status2 === 'EXPANDED') {
    return 'EXPANDED';
  }
  if (status1 === 'COLLAPSED' || status2 === 'COLLAPSED') {
    return 'COLLAPSED';
  }
  return 'NO_TRUNCATION';
};

const useShowMoreText = (text: string, cutoff: number) => {
  const initiallyTruncated = text.length > cutoff;
  const [truncated, setTruncated] = useState<boolean>(initiallyTruncated);

  if (initiallyTruncated) {
    const expandedStatus: ExpandedStatus = truncated ? 'COLLAPSED' : 'EXPANDED';
    const toggleExpanded = () => {
      setTruncated(!truncated);
    };
    const displayText = truncated ? `${text.substring(0, cutoff)}...` : text;
    return {
      displayText,
      expandedStatus,
      toggleExpanded,
    };
  }
  const expandedStatus: ExpandedStatus = 'NO_TRUNCATION';
  return {
    displayText: text,
    expandedStatus,
    toggleExpanded: () => {},
  };
};
export default useShowMoreText;
