import React, { Suspense } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { datadogRum } from '@datadog/browser-rum';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ThemeProvider, StyledEngineProvider } from '@mui/material';
import { FullScreenError, FullScreenSpinner } from 'components/FullScreen';
import { getMuiTheme } from '@insights-ltd/design-library/themes';
import { DATADOG_RUM_CONFIGS } from 'variables';
import { BetaWidget } from '@insights-ltd/design-library/beta';
import { BetaProvider } from '@insights-ltd/design-library/components';
import { browserLang } from '@insights-ltd/design-library/utils';
import { LocalizationProvider } from '@insights-ltd/design-library/providers';
import FilterProvider from 'components/FilterProvider/FilterProvider';
import { CreateBulkJob } from './pages/CreateBulkJob';
import { Job } from './pages/Job';
import { Search } from './pages/Search';
import { Jobs } from './pages/Jobs';
import { CreateJob } from './pages/CreateJob';
import { EditJob } from './pages/EditJob';
import AuthenticationProvider from './components/Authentication';
import Header from './components/Header';
import AppWrapper from './components/AppWrapper/AppWrapper';

import { BETA_DEFAULT, getBetaAvailable } from './features';

const theme = getMuiTheme();
const betaAvailable = getBetaAvailable();

type ErrorReporter = (error: Error, info: React.ErrorInfo) => void;
// eslint-disable-next-line no-console
let errorReporter: ErrorReporter = (error, info) => console.error(error, info);

if (import.meta.env.PROD) {
  const hostConfig = DATADOG_RUM_CONFIGS.find(
    (config) => config.hostname === window.location.host,
  );

  if (hostConfig) {
    errorReporter = (error, info) => {
      datadogRum.addError(error, info);
    };
  }
}

const productionQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

const ErrorFallback = () => {
  return <FullScreenError message="Sorry, an error has occurred" />;
};

const AppRoutes = () => {
  return (
    <BrowserRouter basename={import.meta.env.BASE_URL}>
      <Header />
      <AppWrapper>
        <Routes>
          <Route path="/jobs" element={<Jobs />} />
          <Route path="/jobs/create" element={<CreateJob />} />
          <Route path="/jobs/createBulkJob/*" element={<CreateBulkJob />} />
          <Route path="/jobs/:jobId" element={<Job />} />
          <Route path="/jobs/:jobId/edit" element={<EditJob />} />
          <Route path="/jobs/:jobId/strings" element={<Search />} />
          <Route path="*" element={<Navigate to="/jobs" replace />} />
        </Routes>
      </AppWrapper>
    </BrowserRouter>
  );
};

export const AppProviders = ({
  queryClient,
  children,
}: {
  queryClient: QueryClient;
  children: React.ReactNode;
}) => (
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <LocalizationProvider adapterLocale={browserLang()}>
        <QueryClientProvider client={queryClient}>
          <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onError={errorReporter}
          >
            <FilterProvider>
              {import.meta.env.MODE !== 'test' && betaAvailable && (
                <BetaWidget />
              )}
              <Suspense fallback={<FullScreenSpinner />}>{children}</Suspense>
            </FilterProvider>
          </ErrorBoundary>
        </QueryClientProvider>
      </LocalizationProvider>
    </ThemeProvider>
  </StyledEngineProvider>
);

const App = () => (
  <BetaProvider betaDefault={BETA_DEFAULT} enabled={getBetaAvailable()}>
    <AppProviders queryClient={productionQueryClient}>
      <AuthenticationProvider>
        <AppRoutes />
      </AuthenticationProvider>
    </AppProviders>
  </BetaProvider>
);

export default App;
