import React from 'react';
import { MenuItem, Select, SelectProps } from '@mui/material';
import { JobDialectOption } from 'variables';

const DialectSelect = React.forwardRef<
  HTMLSelectElement,
  SelectProps & { options: JobDialectOption[] }
>(
  (
    {
      SelectDisplayProps = { 'aria-label': 'Languages' },
      required,
      options,
      ...rest
    },
    ref,
  ) => (
    <Select SelectDisplayProps={SelectDisplayProps} inputRef={ref} {...rest}>
      {options.map((item) => (
        <MenuItem key={item.id} value={item.dialect}>
          {item.title}
        </MenuItem>
      ))}
    </Select>
  ),
);

DialectSelect.displayName = 'DialectSelect';

export default DialectSelect;
