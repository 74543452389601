import React from 'react';
import {
  Dialog,
  IconButton,
  List,
  ListItem,
  Grid,
  Button,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { logout } from 'api';
import { useAuth } from 'components/Authentication/AuthenticationProvider';
import HeaderLayout from './HeaderLayout';

type Props = {
  open: boolean;
  closeDialog: () => void;
};

const MobileNavBarDialog = ({ open, closeDialog }: Props) => {
  const { username: loggedIn } = useAuth();

  const doLogout = async () => {
    if (
      /* eslint no-alert: "off" */
      window.confirm(
        'Are you sure you’d like to sign out? You may be signed in to other tools using the same credentials; if you sign out here, you may be required to sign in again elsewhere.',
      )
    ) {
      await logout();
    }
  };

  return (
    <Dialog fullScreen open={open} onClose={closeDialog}>
      <HeaderLayout>
        <Grid container justifyContent="flex-end">
          <IconButton
            onClick={closeDialog}
            aria-label="Close Button"
            size="small"
          >
            <CloseIcon />
          </IconButton>
        </Grid>
      </HeaderLayout>
      <List>
        {loggedIn ? (
          <>
            <ListItem>
              <Typography>{loggedIn}</Typography>
            </ListItem>

            <ListItem>
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                onClick={() => {
                  doLogout().then();
                }}
              >
                Log Out
              </Button>
            </ListItem>
          </>
        ) : null}
      </List>
    </Dialog>
  );
};

export default MobileNavBarDialog;
