import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Grid, Link, Pagination, styled } from '@mui/material';
import { IJob, IJobString } from 'types/job';
import { EnumJobStatus } from 'variables';
import StringList from 'components/Strings/List/StringList';
import usePaginator from 'components/hooks/usePagination';
import RemovingStringsControl from './RemovingStringsControl';
import StringControl from './StringControl';

interface IPropsType {
  strings: [string, IJobString[]][];
  isJobEditable: boolean;
  job: IJob;
  removingStrings: boolean;
  selectedStrings?: string[];
  setSelectedStrings: (key: string[]) => void;
  stopRemovingStrings: () => void;
  startRemovingStrings: () => void;
}

const LinkWrapper = styled('div')({
  paddingTop: '1rem',
});

const ITEMS_PER_PAGE = 10;

const JobStrings = ({
  strings,
  job,
  isJobEditable,
  removingStrings,
  selectedStrings,
  setSelectedStrings,
  stopRemovingStrings,
  startRemovingStrings,
}: IPropsType) => {
  const editStringsUri = `/jobs/${job.id}/strings`;

  const allStringsSelected =
    !!selectedStrings && selectedStrings.length === strings.length;

  const onSelectAllStrings = () =>
    setSelectedStrings(strings.map(([key]) => key));
  const {
    pageNumber,
    pageCount,
    pagedItems,
    handlePageChange,
    getPaginationAriaLabel,
  } = usePaginator(strings, ITEMS_PER_PAGE);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {removingStrings ? (
          <RemovingStringsControl
            allStringsSelected={allStringsSelected}
            onSelectAllStrings={onSelectAllStrings}
            setSelectedStrings={setSelectedStrings}
            stopRemovingStrings={stopRemovingStrings}
          />
        ) : (
          <StringControl
            editable={isJobEditable}
            editStringsUri={editStringsUri}
            totalStrings={strings.length}
            onClickRemove={startRemovingStrings}
          />
        )}
      </Grid>

      {strings.length === 0 && job.status === EnumJobStatus.CANCELLED && (
        <Grid item xs={12}>
          <LinkWrapper>There aren&apos;t any strings in this job.</LinkWrapper>
        </Grid>
      )}

      {strings.length === 0 && job.status !== EnumJobStatus.CANCELLED && (
        <Grid item xs={12}>
          <LinkWrapper>
            You haven&apos;t added any strings yet.{' '}
            <Link component={RouterLink} to={editStringsUri}>
              Get Started
            </Link>
          </LinkWrapper>
        </Grid>
      )}

      {strings.length > 0 && (
        <Grid
          container
          item
          xs={12}
          spacing={1}
          alignItems="center"
          justifyContent="center"
        >
          <StringList
            jobId={job.id}
            dialect={job.dialect}
            translations={job.translations || []}
            strings={pagedItems}
            selectedStrings={selectedStrings || []}
            setSelectedStrings={setSelectedStrings}
            selectable={removingStrings}
          />
        </Grid>
      )}

      {pageCount > 1 && (
        <Grid container>
          <Grid item sx={{ marginLeft: 'auto', padding: '1rem' }}>
            <Pagination
              count={pageCount}
              getItemAriaLabel={getPaginationAriaLabel}
              hidePrevButton
              onChange={(_, page) => handlePageChange(page)}
              page={pageNumber}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default JobStrings;
