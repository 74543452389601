import { IJob, IJobString, JobTranslation } from 'types/job';
import { EnumJobStatus } from 'variables';

export function generateMockJobTranslations(
  numTranslations: number,
): JobTranslation[] {
  const mockTranslations: JobTranslation[] = [];

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < numTranslations; i++) {
    const translation: JobTranslation = {
      key: `translation_${i + 1}`,
    };

    mockTranslations.push(translation);
  }

  return mockTranslations;
}

export function generateMockTranslations(
  numTranslations: number,
  activeJobId?: string,
): IJobString[] {
  const mockTranslations: IJobString[] = [];

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < numTranslations; i++) {
    const translation: IJobString = {
      dialect: 'fr-FR',
      value: `Mock Translation ${i + 1}`,
      grammarContext: `NaN`,
      key: `translation_${i + 1}`,
      activeJobId,
    };

    mockTranslations.push(translation);
  }

  return mockTranslations;
}

const description: string =
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla consequat justo vel felis eleifend, nec ultricies enim vehicula. Sed eget sapien sed libero aliquet posuere. Cras pharetra, lorem id convallis facilisis, mi mi consequat ipsum, eu fringilla magna dolor in mi. Ut nec justo quis nunc volutpat fringilla. Integer consequat augue in risus tempor, quis feugiat mauris consectetur. Nam vitae consequat justo. Sed sit amet nulla eget lacus vestibulum finibus. Nullam id velit nec risus efficitur eleifend. Sed rhoncus mauris eget elit mollis, ac rhoncus justo faucibus. Fusce tincidunt quam quis eros ultricies tincidunt.';

export function generateMockJobs(numJobs: number): IJob[] {
  const mockJobs: IJob[] = [];

  const statuses: EnumJobStatus[] = [...Object.values(EnumJobStatus)];

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < numJobs; i++) {
    const job: IJob = {
      id: `job_${i + 1}`,
      title: `Mock Job ${i + 1}`,
      dialect: 'en-GB',
      status: statuses[i % 7],
      owner: `Owner_${i + 1}`,
      translator: `Translator_${i + 1}`,
      description,
      translations: generateMockJobTranslations(2),
    };

    mockJobs.push(job);
  }

  return mockJobs;
}
