import React from 'react';
import Grid from '@mui/material/Grid';
import Pagination from '@mui/material/Pagination';
import Button from '@mui/material/Button';
import { FullScreenError, FullScreenSpinner } from 'components/FullScreen';
import { JobTranslation } from 'types/job';
import { IDialects } from 'variables';
import StringList from './List/StringList';
import useSearchListHooks from './useSearchListHooks';

const halfHeight = { height: '50vh' };

type SearchListProps = {
  jobId?: string;
  dialect: keyof IDialects;
  translations: JobTranslation[];
  searchText: string | undefined;
  selectedFilter: string[];
  selectedStrings: string[];
  setSelectedStrings: (strings: string[]) => void;
};
const SearchList = ({
  jobId,
  dialect,
  translations,
  searchText,
  selectedFilter,
  selectedStrings,
  setSelectedStrings,
}: SearchListProps) => {
  const hook = useSearchListHooks(jobId, dialect, searchText, selectedFilter);

  const onSelectAllStrings = () => {
    const uniqueKeys = Array.from(
      new Set([...selectedStrings, ...hook.selectableStrings]),
    );
    setSelectedStrings(uniqueKeys);
  };

  if (hook.isFetching) {
    return <FullScreenSpinner message="Loading..." style={halfHeight} />;
  }
  if (hook.isError) {
    return (
      <FullScreenError
        message="There was an error fetching translation strings data, please try refresh the page."
        style={halfHeight}
      />
    );
  }

  const allStringsSelected =
    selectedStrings.length === hook.selectableStrings.length;

  const partSelected = selectedStrings.length;

  const selectDeselectButton = (): React.ReactNode => {
    if (
      partSelected < hook.selectableStrings.length &&
      !hook.isError &&
      !hook.isFetching
    ) {
      return (
        <Button
          variant="outlined"
          color="primary"
          onClick={
            allStringsSelected
              ? () => setSelectedStrings([])
              : onSelectAllStrings
          }
        >
          Select all strings
        </Button>
      );
    }
    if (
      hook.selectableStrings.length === 0 &&
      !hook.isError &&
      !hook.isFetching
    ) {
      return (
        <Button
          color="primary"
          disabled
          onClick={
            allStringsSelected
              ? () => setSelectedStrings([])
              : onSelectAllStrings
          }
        >
          Disabled
        </Button>
      );
    }
    return (
      <Button
        variant="outlined"
        color="primary"
        onClick={
          allStringsSelected ? () => setSelectedStrings([]) : onSelectAllStrings
        }
      >
        Deselect all strings
      </Button>
    );
  };

  return (
    <>
      <Grid
        container
        item
        sm={12}
        sx={(theme) => ({
          justifyContent: 'flex-end',
          padding: theme.spacing(2),
        })}
      >
        {hook.pageCount > 1 && (
          <Pagination
            count={hook.pageCount}
            getItemAriaLabel={hook.getPaginationAriaLabel}
            hidePrevButton
            onChange={(_, page) => hook.handlePageChange(page)}
            page={hook.pageNumber}
          />
        )}
      </Grid>
      <Grid
        container
        item
        sm={12}
        spacing={1}
        alignItems="center"
        justifyContent="center"
      >
        <>
          <Grid container justifyContent="flex-end">
            {selectDeselectButton()}
          </Grid>
          <StringList
            selectable
            showAlreadyInThisJob
            dialect={dialect}
            translations={translations}
            jobId={jobId}
            strings={hook.pagedItems}
            selectedStrings={selectedStrings}
            setSelectedStrings={setSelectedStrings}
          />
        </>
      </Grid>
      <Grid
        container
        item
        sm={12}
        sx={(theme) => ({
          justifyContent: 'flex-end',
          padding: theme.spacing(2),
        })}
      >
        {hook.pageCount > 1 && (
          <Pagination
            count={hook.pageCount}
            getItemAriaLabel={hook.getPaginationAriaLabel}
            hidePrevButton
            onChange={(_, page) => hook.handlePageChange(page)}
            page={hook.pageNumber}
          />
        )}
      </Grid>
    </>
  );
};

export default SearchList;
