import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import {
  addStringsToJob,
  createJob,
  editJob,
  getFilteredJobs,
  getJob,
  GetJobsParams,
  getTranslations,
  login,
  importStrings,
  publishJob,
  getTranslationKeys,
  getJobTranslations,
} from 'api';
import { StringsResponseList } from 'types/api';
import { groupStringsByKey } from 'utils/utils';
import { IDialects } from 'variables';
import { CreateBulkJobType } from 'types/job';

type QueryOptionsConfig<T> = Omit<UseQueryOptions<T>, 'queryKey' | 'queryFn'>;

export const useGetJob = (jobId: string) =>
  useQuery({
    queryKey: ['jobData', jobId],
    queryFn: async () => getJob(jobId),
  });

export const useGetJobs = (jobFilters?: GetJobsParams) =>
  useQuery({
    queryKey: ['jobsData', jobFilters],
    queryFn: async () => getFilteredJobs(jobFilters),
  });

export const useGetJobTranslations = (
  jobId: string,
  options: QueryOptionsConfig<StringsResponseList>,
) =>
  useQuery<StringsResponseList>({
    queryKey: ['jobTranslationsData', jobId],
    queryFn: async () => {
      const translations = await getJobTranslations(jobId);
      return groupStringsByKey(translations);
    },
    ...options,
  });

export const useGetTranslationsKeys = () =>
  useQuery({
    queryKey: ['translationsKeys'],
    queryFn: getTranslationKeys,
  });

export const useEditJob = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: editJob,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['jobTranslationsData'] });
      queryClient.invalidateQueries({ queryKey: ['jobData'] });
    },
  });
};

export const useCreateJob = () => useMutation({ mutationFn: createJob });
export class BulkCreateJobError extends Error {
  bulkCreateJobStatus: BulkCreateJobStatus;

  constructor(bulkCreateJobStatus: BulkCreateJobStatus) {
    super('bulk job fail');
    this.name = 'bulk job fail, multi-upload failed.';
    this.bulkCreateJobStatus = bulkCreateJobStatus;
  }
}

type BulkCreateJobStatus = Partial<
  Record<keyof IDialects, { jobId?: string; stringAdded?: boolean }>
>;

export const useBulkCreateJob = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      job: { multiDialect, ...job },
      strings,
    }: {
      job: CreateBulkJobType;
      strings: string[];
    }) => {
      const bulkCreateJobStatus = multiDialect.reduce<BulkCreateJobStatus>(
        (acc, dialect) => {
          return {
            ...acc,
            [dialect]: {
              stringAdded: false,
            },
          };
        },
        {},
      );

      const sequentiallyCreateJobs = async (
        dialects: (keyof IDialects)[],
      ): Promise<void> => {
        try {
          const [dialect, ...rest] = dialects;
          if (dialect) {
            const { id: newJobId } = await createJob({ ...job, dialect });
            bulkCreateJobStatus[dialect] = {
              ...bulkCreateJobStatus[dialect],
              jobId: newJobId,
            };
            await addStringsToJob({ jobId: newJobId, keys: strings });
            bulkCreateJobStatus[dialect] = {
              ...bulkCreateJobStatus[dialect],
              stringAdded: true,
            };
            await sequentiallyCreateJobs(rest);
          }
        } catch (e) {
          throw new BulkCreateJobError(bulkCreateJobStatus);
        }
      };
      return sequentiallyCreateJobs(multiDialect);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['jobTranslationsData'] });
      queryClient.invalidateQueries({ queryKey: ['translationsData'] });
      queryClient.invalidateQueries({ queryKey: ['jobData'] });
    },
  });
};

export const useGetTranslations = (
  dialect: string,
  search: string,
  keys: string[],
  options: QueryOptionsConfig<StringsResponseList> = {},
) =>
  useQuery<StringsResponseList>({
    queryKey: ['translationsData', dialect, search, keys],
    queryFn: async () => getTranslations(dialect, search, keys),
    ...options,
  });

export const useLogin = () =>
  useQuery({
    queryKey: ['userData'],
    queryFn: login,
  });

export const useImportStrings = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: importStrings,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['jobTranslationsData'] });
      queryClient.invalidateQueries({ queryKey: ['jobData'] });
    },
  });
};

export const usePublishJob = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: publishJob,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['jobTranslationsData'] });
      queryClient.invalidateQueries({ queryKey: ['jobData'] });
    },
  });
};

export const useSaveStrings = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: addStringsToJob,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['jobTranslationsData'] });
      queryClient.invalidateQueries({ queryKey: ['translationsData'] });
      queryClient.invalidateQueries({ queryKey: ['jobData'] });
    },
  });
};
