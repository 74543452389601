import React from 'react';
import Select, { SelectProps } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { TRANSLATORS } from 'variables';

const TranslatorsSelect = React.forwardRef<HTMLSelectElement, SelectProps>(
  ({ SelectDisplayProps = { 'aria-label': 'Translators' }, ...rest }, ref) => (
    <Select SelectDisplayProps={SelectDisplayProps} inputRef={ref} {...rest}>
      {TRANSLATORS.map(({ text, value }) => (
        <MenuItem key={value} value={value}>
          {text}
        </MenuItem>
      ))}
    </Select>
  ),
);

TranslatorsSelect.displayName = 'TranslatorsSelect';

export default TranslatorsSelect;
