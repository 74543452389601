import { DATE_GLOBAL_FORMAT, DATE_GLOBAL_LOCALE } from 'variables';
import { DateTime } from 'luxon';
import { ITranslationsResponse, StringsResponseList } from 'types/api';

export const dateFromISOToLocal = (
  date: string | undefined,
): string | undefined => {
  if (date) {
    return DateTime.fromISO(date)
      .setLocale(DATE_GLOBAL_LOCALE)
      .toLocaleString();
  }

  return undefined;
};

export const dateFromLocalToISO = (
  date: string | undefined,
): string | undefined => {
  if (date) return DateTime.fromFormat(date, DATE_GLOBAL_FORMAT).toISO() ?? '';

  return undefined;
};

export const fromLocalFormatToDateTime = (date: string): DateTime => {
  return DateTime.fromFormat(date, DATE_GLOBAL_FORMAT);
};

export const groupStringsByKey = (
  content: ITranslationsResponse,
): StringsResponseList =>
  content.reduce<StringsResponseList>((acc, currentString) => {
    // eslint-disable-next-line no-param-reassign
    acc[currentString.key] = [...(acc[currentString.key] || []), currentString];
    return acc;
  }, Object.create(null));
