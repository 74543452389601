import React from 'react';
import { styled, Typography } from '@mui/material';

const StyledDiv = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
});

const FullScreenError = ({
  message,
  style,
}: {
  message: string;
  style?: any;
}) => {
  return (
    <StyledDiv style={style}>
      <Typography variant="h6" component="h1">
        Error
      </Typography>
      <Typography>{message}</Typography>
    </StyledDiv>
  );
};

export default FullScreenError;
