import React from 'react';

const useJobDialog = () => {
  const [open, setOpen] = React.useState<boolean>(false);
  const openDialog = () => setOpen(true);
  const closeDialog = () => setOpen(false);
  return { open, openDialog, closeDialog };
};

export default useJobDialog;
