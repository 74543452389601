import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useReducer,
} from 'react';

export interface FiltersType {
  dialect: string;
  status: {
    DRAFT: boolean;
    AWAITING_QUOTE: boolean;
    WITH_TRANSLATOR: boolean;
    IN_REVIEW: boolean;
    PENDING_PUBLISH: boolean;
    PUBLISHED: boolean;
    CANCELLED: boolean;
  };
  deadline: {
    startDate: string;
    endDate: string;
  };
  owner: string;
  translator: string;
  requester: string;
}

export const DEFAULT_FILTERS: FiltersType = {
  dialect: '',
  status: {
    DRAFT: true,
    AWAITING_QUOTE: true,
    WITH_TRANSLATOR: true,
    IN_REVIEW: true,
    PENDING_PUBLISH: true,
    PUBLISHED: false,
    CANCELLED: false,
  },
  deadline: {
    startDate: '',
    endDate: '',
  },
  owner: '',
  translator: '',
  requester: '',
};

export const EMPTY_FILTERS = {
  dialect: '',
  status: {
    DRAFT: false,
    AWAITING_QUOTE: false,
    WITH_TRANSLATOR: false,
    IN_REVIEW: false,
    PENDING_PUBLISH: false,
    PUBLISHED: false,
    CANCELLED: false,
  },
  deadline: {
    startDate: '',
    endDate: '',
  },
  owner: '',
  translator: '',
  requester: '',
};

interface FilterState extends FiltersType {
  lastJobOpened?: string;
}

export const FilterContext = createContext<
  | {
      sessionData?: FilterState;
      filterState: FilterState;
      setFilterState: React.Dispatch<Partial<FilterState>>;
      setFilter: (statuses: Partial<FilterState['status']>) => void;
      resetState: () => void;
      clearState: () => void;
    }
  | undefined
>(undefined);

export const useFilterContext = () => {
  const context = useContext(FilterContext);

  if (context === undefined) {
    throw Error(
      'context was undefined. Please ensure useFilterContext is used within a FilterProvider.',
    );
  }

  return context;
};

const initialFilter: FilterState = {
  ...DEFAULT_FILTERS,
};

const FilterProvider = ({
  children,
  overwrite,
}: {
  children: React.ReactNode;
  overwrite?: Partial<FilterState>;
}) => {
  const sessionData = sessionStorage.getItem('localisation-filter');
  const [filterState, setFilterState] = useReducer(
    (data: FilterState, partialData: Partial<FilterState>) => ({
      ...data,
      ...partialData,
    }),
    sessionData ? JSON.parse(sessionData) : initialFilter,
  );

  const resetState = useCallback(() => {
    setFilterState(initialFilter);
    sessionStorage.setItem(
      'localisation-filter',
      JSON.stringify(initialFilter),
    );
  }, []);

  const clearState = () => {
    setFilterState(EMPTY_FILTERS);
    sessionStorage.setItem(
      'localisation-filter',
      JSON.stringify(EMPTY_FILTERS),
    );
  };

  const value = useMemo(() => {
    const handleFilterState = (state: Partial<FilterState>) => {
      sessionStorage.setItem(
        'localisation-filter',
        JSON.stringify({ ...filterState, ...state }),
      );
      setFilterState({ ...filterState, ...state });
    };
    const setFilter = (statuses: Partial<FilterState['status']>) => {
      setFilterState({
        ...filterState,
        status: { ...filterState.status, ...statuses },
      });

      sessionStorage.setItem(
        'localisation-filter',
        JSON.stringify({
          ...filterState,
          status: { ...filterState.status, ...statuses },
        }),
      );
    };

    return {
      sessionData: sessionData ? JSON.parse(sessionData) : undefined,
      filterState,
      resetState,
      clearState,
      setFilter,
      setFilterState: handleFilterState,
      ...overwrite,
    };
  }, [filterState, resetState, overwrite, sessionData]);

  return (
    <FilterContext.Provider value={value}>{children}</FilterContext.Provider>
  );
};

export default FilterProvider;
