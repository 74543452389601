import * as React from 'react';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import { IJob } from 'types/job';
import Header from './TableHeader';
import TableBody from './TableBody';

interface IPropsType {
  jobs: IJob[];
  order: 'asc' | 'desc';
  orderBy: keyof IJob;
  handleSort: (property: keyof IJob) => void;
  navigateToJob: (job: IJob) => void;
}

const JobsTable = ({
  jobs,
  order,
  orderBy,
  handleSort,
  navigateToJob,
}: IPropsType) => {
  return (
    <Paper elevation={0}>
      <TableContainer>
        <Table aria-labelledby="translation-jobs" size="small">
          <Header order={order} orderBy={orderBy} onSort={handleSort} />
          <TableBody jobs={jobs} navigateToJob={navigateToJob} />
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default JobsTable;
