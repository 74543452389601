import React from 'react';

export interface StringSelection {
  stopRemovingStrings: () => void;
  startRemovingStrings: () => void;
  selectedStrings: string[];
  removingStrings: boolean;
  setSelectedStrings: (strings: string[]) => void;
}

export const useStringSelection = (): StringSelection => {
  const [selectedStrings, setSelectedStrings] = React.useState<string[]>([]);
  const [removingStrings, setRemovingStrings] = React.useState<boolean>(false);
  const stopRemovingStrings = React.useCallback(() => {
    setRemovingStrings(false);
    setSelectedStrings([]);
  }, []);
  const startRemovingStrings = React.useCallback(() => {
    setRemovingStrings(true);
  }, []);
  return {
    stopRemovingStrings,
    startRemovingStrings,
    selectedStrings,
    removingStrings,
    setSelectedStrings,
  };
};
