import { DateTime } from 'luxon';
import { IJob } from 'types/job';

const compareJobs = <T>(a: T, b: T, orderBy: keyof T) => {
  if (b[orderBy] < a[orderBy]) return -1;
  if (b[orderBy] > a[orderBy]) return 1;
  return 0;
};

const extractJobIdNumber = <T>(jobId: T): number =>
  parseInt((jobId as unknown as string).split('-')[1], 10);

const compareJobsByIdNumber = <T>(a: T, b: T, orderBy: keyof T) => {
  const aIdNumber: number = extractJobIdNumber(a[orderBy]);
  const bIdNumber: number = extractJobIdNumber(b[orderBy]);

  if (bIdNumber < aIdNumber) return -1;
  if (bIdNumber > aIdNumber) return 1;
  return 0;
};

const compareJobsByDeadline = <T>(
  a: T,
  b: T,
  orderBy: keyof T,
  order: string,
) => {
  const aDeadline: number = DateTime.fromISO(String(a[orderBy])).toMillis();
  const bDeadline: number = DateTime.fromISO(String(b[orderBy])).toMillis();

  if (aDeadline === bDeadline) return 0;
  if (bDeadline > aDeadline) return 1;
  if (bDeadline < aDeadline) return -1;
  if (order === 'asc') {
    if (Number.isNaN(aDeadline)) return -1;
    if (Number.isNaN(bDeadline)) return 1;
  } else {
    if (Number.isNaN(aDeadline)) return 1;
    if (Number.isNaN(bDeadline)) return -1;
  }

  return 0;
};

const getComparotor = (orderBy: keyof IJob) => {
  switch (orderBy) {
    case 'id':
      return compareJobsByIdNumber;
    case 'deadlineDate':
      return compareJobsByDeadline;
    default:
      return compareJobs;
  }
};

const sortJobs = (
  jobs: IJob[],
  order: 'asc' | 'desc',
  orderBy: keyof IJob,
): IJob[] => {
  const comparotor = getComparotor(orderBy);
  return [...jobs].sort((a, b) => {
    return order === 'desc'
      ? comparotor(a, b, orderBy, order)
      : -comparotor(a, b, orderBy, order);
  });
};
export default sortJobs;
