import React from 'react';
import { SvgComponentProps } from 'components/Svgs/types';

const SvgCheckboxChecked = ({ title, ...props }: SvgComponentProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    width="24"
    height="25"
    viewBox="0 0 24 25"
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <rect
      width={23}
      height={23}
      x={0.5}
      y={0.619}
      fill="#0E567B"
      stroke="#0E567B"
      rx={3.5}
    />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="m17 7.12-6.708 9.58a.978.978 0 0 1-1.586.027L7 14.453"
    />
  </svg>
);

export default SvgCheckboxChecked;
