import { Grid } from '@mui/material';
import React from 'react';
import { useAuth } from 'components/Authentication/AuthenticationProvider';
import AccountWidget from './AccountWidget';

export const NavBar = () => {
  const { username: loggedIn } = useAuth();

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      sx={(theme) => ({ '> * + *': { marginLeft: theme.spacing(4) } })}
    >
      <span />
      {loggedIn ? <AccountWidget /> : null}
    </Grid>
  );
};
