import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import './TreeView.scss';
import './TreeViewLines.scss';
import { TranslationKeys } from 'types/api';
import CheckboxTree, { Node } from 'react-checkbox-tree';

import CheckBoxChecked from './assets/CheckboxChecked';
import CheckBoxUnchecked from './assets/CheckboxUnchecked';
import CheckBoxPartial from './assets/CheckboxPartial';
import Expand from './assets/Expand';
import Collapse from './assets/Collapse';

const convertData = (keysObject: TranslationKeys, path: string): Node[] =>
  Object.keys(keysObject).map((item) => {
    const newPath = `${path}.${item}`;
    const newTree = keysObject[item] as TranslationKeys;
    const node: Node = Object.keys(newTree).length
      ? { value: newPath, label: item, children: convertData(newTree, newPath) }
      : { value: newPath, label: item };
    return node;
  });

type TreeViewProps = {
  keysObject: TranslationKeys;
  path: string;
  handleSelected: (value: string[]) => void;
};

const TreeView = ({ keysObject, path, handleSelected }: TreeViewProps) => {
  const [checked, setChecked] = useState<string[]>([]);
  const [expanded, setExpanded] = useState<string[]>([]);
  const nodes = convertData(keysObject, path);

  useEffect(() => {
    const results: string[] = [];
    let newList = checked;
    while (newList.length) {
      const key = newList[0];
      newList = newList.filter((row) => !row.match(key));
      results.push(key);
    }
    handleSelected(results);
  }, [handleSelected, checked]);

  return (
    <Box>
      <CheckboxTree
        nodes={nodes}
        checked={checked}
        expanded={expanded}
        onCheck={setChecked}
        onExpand={setExpanded}
        nativeCheckboxes={false}
        checkModel="all"
        icons={{
          check: <CheckBoxChecked />,
          uncheck: <CheckBoxUnchecked />,
          halfCheck: <CheckBoxPartial />,
          expandClose: <Expand />,
          expandOpen: <Collapse />,
          expandAll: <h1>Expand All</h1>,
          collapseAll: <h1>Collapse All</h1>,
          parentClose: null,
          parentOpen: null,
          leaf: null,
        }}
      />
    </Box>
  );
};

export default TreeView;
