// TODO this whole file should be amalgamated into the constants.ts dialects
export const ENGLISH_DIALECT = 'en-GB';

export const TRANSLATION_JOB_DIALECTS = {
  'af-ZA': 'Afrikaans',
  'id-ID': 'Bahasa (Indonesia)',
  'bg-BG': 'Bulgarian',
  'zh-CN': 'Chinese (Simplified)',
  'zh-HK': 'Chinese (Traditional)',
  'hr-HR': 'Croatian',
  'cs-CZ': 'Czech',
  'da-DK': 'Danish',
  'nl-NL': 'Dutch',
  [ENGLISH_DIALECT]: 'English',
  'en-ZA': 'English (South Africa)',
  'et-EE': 'Estonian',
  'fi-FI': 'Finnish',
  'fr-CA': 'French (Canada)',
  'fr-FR': 'French (France)',
  'ka-GE': 'Georgian',
  'de-DE': 'German',
  'el-GR': 'Greek',
  'hu-HU': 'Hungarian',
  'is-IS': 'Icelandic',
  'it-IT': 'Italian',
  'ja-JP': 'Japanese',
  'ko-KR': 'Korean',
  'lt-LT': 'Lithuanian',
  'nb-NO': 'Norwegian',
  'pl-PL': 'Polish',
  'pt-BR': 'Portuguese (Brazil)',
  'pt-PT': 'Portuguese (Portugal)',
  'ro-RO': 'Romanian',
  'ru-RU': 'Russian',
  'sr-RS': 'Serbian (Latin)',
  'sk-SK': 'Slovak',
  'sl-SI': 'Slovenian',
  'es-MX': 'Spanish (Mexico)',
  'es-ES': 'Spanish (Spain)',
  'sv-SE': 'Swedish',
  'tr-TR': 'Turkish',
  'uk-UA': 'Ukrainian',
  'vi-VN': 'Vietnamese',
};

export type IDialects = typeof TRANSLATION_JOB_DIALECTS;

export type Dialect = keyof IDialects;

export const DIALECTS = [
  {
    text: 'Afrikaans',
    languageTag: 'af-ZA',
  },
  {
    text: 'Bahasa (Indonesia)',
    languageTag: 'id-ID',
  },
  {
    text: 'Bulgarian',
    languageTag: 'bg-BG',
  },
  {
    text: 'Chinese (Simplified)',
    languageTag: 'zh-CN',
  },
  {
    text: 'Chinese (Traditional)',
    languageTag: 'zh-HK',
  },
  {
    text: 'Croatian',
    languageTag: 'hr-HR',
  },
  {
    text: 'Czech',
    languageTag: 'cs-CZ',
  },
  {
    text: 'Danish',
    languageTag: 'da-DK',
  },
  {
    text: 'Dutch',
    languageTag: 'nl-NL',
  },
  {
    text: 'English',
    languageTag: 'en-GB',
  },
  {
    text: 'English (South Africa)',
    languageTag: 'en-ZA',
  },
  {
    text: 'Estonian',
    languageTag: 'et-EE',
  },
  {
    text: 'Finnish',
    languageTag: 'fi-FI',
  },
  {
    text: 'French (Canada)',
    languageTag: 'fr-CA',
  },
  {
    text: 'French (France)',
    languageTag: 'fr-FR',
  },
  {
    text: 'Georgian',
    languageTag: 'ka-GE',
  },
  {
    text: 'German',
    languageTag: 'de-DE',
  },
  {
    text: 'Greek',
    languageTag: 'el-GR',
  },
  {
    text: 'Hungarian',
    languageTag: 'hu-HU',
  },
  {
    text: 'Icelandic',
    languageTag: 'is-IS',
  },
  {
    text: 'Italian',
    languageTag: 'it-IT',
  },
  {
    text: 'Japanese',
    languageTag: 'ja-JP',
  },
  {
    text: 'Korean',
    languageTag: 'ko-KR',
  },
  {
    text: 'Lithuanian',
    languageTag: 'lt-LT',
  },
  {
    text: 'Norwegian',
    languageTag: 'nb-NO',
  },
  {
    text: 'Polish',
    languageTag: 'pl-PL',
  },
  {
    text: 'Portuguese (Brazil)',
    languageTag: 'pt-BR',
  },
  {
    text: 'Portuguese (Portugal)',
    languageTag: 'pt-PT',
  },
  {
    text: 'Romanian',
    languageTag: 'ro-RO',
  },
  {
    text: 'Russian',
    languageTag: 'ru-RU',
  },
  {
    text: 'Serbian (Latin)',
    languageTag: 'sr-RS',
  },
  {
    text: 'Slovak',
    languageTag: 'sk-SK',
  },
  {
    text: 'Slovenian',
    languageTag: 'sl-SI',
  },
  {
    text: 'Spanish (Mexico)',
    languageTag: 'es-MX',
  },
  {
    text: 'Spanish (Spain)',
    languageTag: 'es-ES',
  },
  {
    text: 'Swedish',
    languageTag: 'sv-SE',
  },
  {
    text: 'Turkish',
    languageTag: 'tr-TR',
  },
  {
    text: 'Ukrainian',
    languageTag: 'uk-UA',
  },
  {
    text: 'Vietnamese',
    languageTag: 'vi-VN',
  },
];

export const TRANSLATORS = [
  {
    text: 'Comtec',
    value: 'COMTEC',
  },
  {
    text: 'Internal',
    value: 'INTERNAL',
  },
];
